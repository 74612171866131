.pitch-dot {
  position: absolute;
  height: 20px;
  width: 20px;
  border-radius: 10px;
  font-size: 10pt;
  color: #fff;
  text-align: center;
  padding-top: 2px;
}
