* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Helvetica Neue";
}

#screen {
  width: 100%;
  height: 100vh;
}

.center {
  text-align: center;
}

.section {
  width: 100%;
  padding: 20px 10px 20px 10px;
  max-width: 900px;
  margin: 0px auto;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
}

.align-middle {
  align-items: center;
}

.align-left {
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left
}

.column {
  flex: 1;
  min-width: 200px;
  margin: 10px;
}

.small-column {
  flex: 1;
  margin: 4px;
  padding: 5px;
}

img {
  max-width: 100%;
  margin: 4px;
}

h1 {
  font-size: 32pt;
  font-weight: 100;
}

h2 {
  font-size: 28pt;
  font-weight: 300;
}

h3 {
  font-size: 24pt;
  font-weight: 300;
}

h4 {
  font-size: 22pt;
  font-weight: 200;
}

h5 {
  font-size: 20pt;
  font-weight: 200;
}

h6 {
  font-size: 18pt;
  font-weight: 200;
}

p,
li,
span,
label {
  font-size: 16pt;
  font-weight: 300;
}

.label {
  font-size: 12pt;
  font-weight: 400;
}

.bold {
  font-weight: 600;
}