.selector {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.options {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
}

.item {
  background: none;
  padding: 0;
  margin: 0;
  border: none;
  padding: 4px 10px;
  border-radius: 14px;
  margin: 4px;
  font-size: 10pt;
  cursor: pointer;
  color: #fff;
}
